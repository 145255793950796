<template>
  <div class="content"></div>
  <slot></slot>
</template>

<style>
.title1 {
  @apply text-3xl md:text-5xl font-medium leading-none text-black;
}

.title2 {
  @apply text-[28px] md:text-4xl font-semibold leading-none text-[#333333] mb-3;
}

.title3 {
  @apply text-lg md:text-2xl font-semibold leading-none text-[#333333] mb-4 md:mb-5;
}

.body {
  @apply text-sm md:text-lg text-[#000000bf];
}
</style>
